@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap);
.section {
  display: flex;
  margin-left: 15%;
  margin-right: 20%;
  margin-bottom: 50px;
  /* border-style: solid; */
}

.itemCard {
  display: flex;
  flex-direction: column;
}

.item {
  /* align-items: flex-start; */
  display: flex;
  margin-bottom: 50px;
  /* border-style: solid; */
}

.cardLeft {
  flex: 0 0 30%;
  text-align: center;
  height: 100%;
  /* background: yellow; */
}
.verticalLine {
  border-left: 2px solid;
  flex: 0 1;
  /* height: 100%; */
  /* border-radius: 100px; */
  /* flex-grow: 1; */
}
.cardRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1 0 50%;
  padding-left: 25px;
  padding-right: 25px;
  /* background: rebeccapurple; */
}

.item-label {
  vertical-align: middle;
  border-style: solid;
}

.last-card {
  margin-bottom: 0;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.languages div {
  color: #72baee;
  margin: 3px;
  padding: 0px 4px 0px 4px;
  border-style: solid;
  border-width: 1.5px;
  border-radius: 5px;
}

.horizontalLine {
  margin-left: 30px;
  margin-right: 30px;
  border-top: 1px solid #3aafb9;
  width: 100%;
  height: 2px;
  border-radius: 100px;
}

.skillPage {
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  padding-bottom: 100px;
  /* border-style: solid; */
}

.skillColumn {
  /* width: 300px; */
  padding-left: 25px;
  padding-right: 25px;
  /* text-align: center; */
}

.leftAlign {
  display: inline-block;
  text-align: left;
}

.skillColumn p {
  margin-bottom: 5px;
}

.date {
  color: #037971;
}

.projectName {
  display: flex;
  flex-wrap: wrap;
}
.projectType {
  display: inline;
  font-style: italic;
  color: #6f42c1;
}

.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #6cc644;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top:hover,
.hvr-sweep-to-top:focus,
.hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before,
.hvr-sweep-to-top:focus:before,
.hvr-sweep-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.githubButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  text-decoration: none;
  color: #000;
  color: initial;
  background-color: white;
  margin-left: 20px;
  border-style: solid;
  border-color: #6cc644;
  border-radius: 5px;
  border-width: 2px;
  padding: 0px 5px 0px 5px;
  font-size: 20px;
}
.githubButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.tiny {
  margin-left: 5px;
  display: inline;
  vertical-align: bottom;
  font-size: 13px;
}

body {
  font-family: "Titillium Web", sans-serif;
}

h1 {
  text-align: center;
}

.typing {
  font-size: 150px;
  color: #001011;
}

.experienceLogo {
  /* height: 30px; */
  height: 35px;
  /* float: left; */
  /* border-style: solid; */
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  /* margin-bottom: 10px; */
}

.contact {
  display: flex;
  justify-content: space-between;
}

.contactImage {
  margin-top: 100px;
  height: 100px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.imageContainer {
  flex: 1 0 33%;
  align-items: center;
  text-align: center;
}

.contactImage:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

h1 {
  font-size: 120px;
  color: #093a3e;
}

h2 {
  font-size: 40px;
  color: #3aafb9;
}

p {
  font-size: 18px;
}

.center {
  width: 50%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.titlePage {
  height: 100vh;
}

.navbar {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  justify-content: space-around;
  background: transparent;
}

.navLink:hover {
  cursor: pointer;
}

.up {
  position: -webkit-sticky;
  position: fixed;
  bottom: 10px;
  right: 10px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.resume {
  border-style: solid;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  margin-bottom: 100px;
}

.resumeImage {
  height: 100%;
}

.resume:hover {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

.up:hover {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-style: solid; */
  margin-bottom: 100px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

highlight {
  color: #7494ea;
  font-weight: bold;
}

.gnome {
  /* display: none; */
  /* position: fixed; */
  /* top: 0px; */
  /* right: 0px; */

  height: 0.5%;
  width: 0.5%;
}

.element {
  /* border-style: solid; */
  /* min-height: 100vh; */
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  padding-top: 50px;
}

h3 {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  /* border-style: solid; */
}

@media only screen and (max-width: 1500px) {
  .itemCard {
    margin: 0;
    padding: 0;
  }
  .section {
    margin: 0;
    padding: 0;
  }
  .center {
    width: 100%;
  }
  .gnome {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .typing {
    font-size: 100px;
  }
  .itemCard {
    margin-left: 200px;
    margin-right: 200px;
    flex-direction: column;
  }
  .section {
    flex-direction: column;
  }
  .cardLeft {
    /* display: none; */
    margin-bottom: 50px;
  }
  .cardRight {
    margin-bottom: 0;
  }
  .verticalLine {
    display: none;
  }
  .horizontalLine {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 850px) {
  .itemCard {
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: column;
  }
  .cardLeft {
    margin-bottom: 50px;
  }
  .cardRight {
    margin-bottom: 0;
  }
  .verticalLine {
    display: none;
  }
  .skillPage {
    flex-direction: column;
    align-items: center;
  }
  .skillColumn {
    margin-bottom: 40px;
  }
  .leftAlign {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  /* TITLE PAGE ------------------------------------------------------------------- */
  .typing {
    font-size: 50px;
  }
  .navbar {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: space-around;
    background: transparent;
  }
  .center {
    width: 100%;
  }
  .contact {
    display: none;
  }

  .divider {
    margin-bottom: 0;
  }
  .up {
    display: none;
  }
  /* ITEM CARD -------------------------------------------------------------------- */
  .date {
    margin-bottom: 50px;
  }
  .introduction {
    margin-bottom: 50px;
  }
  .skillPage {
    border-top: solid;
    border-color: #c0c0c0;
    /* border-style: solid; */
    /* border-radius: 10px; */
    border-width: 1px;
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
  }
  .skillColumn {
    text-align: center;
  }
  .item {
    border-top: solid;
    border-color: #c0c0c0;
    /* border-style: solid; */
    /* border-radius: 10px; */
    border-width: 1px;
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
  }
  .horizontalLine {
    display: none;
  }
  .cardLeft {
    display: none;
  }
  .section {
    /* margin: 10px 10px 10px 10px; */
    /* padding: 10px 10px 10px 10px; */
    margin: 0;
    padding: 0;
  }

  .cardRight {
    padding: 0;
  }

  .title {
    display: none;
  }
  /* PROJECT CARD ----------------------------------------------------------------- */
  .projectName {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .githubButton {
    margin-left: 0;
  }
}

