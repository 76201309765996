.section {
  display: flex;
  margin-left: 15%;
  margin-right: 20%;
  margin-bottom: 50px;
  /* border-style: solid; */
}

.itemCard {
  display: flex;
  flex-direction: column;
}

.item {
  /* align-items: flex-start; */
  display: flex;
  margin-bottom: 50px;
  /* border-style: solid; */
}

.cardLeft {
  flex: 0 0 30%;
  text-align: center;
  height: 100%;
  /* background: yellow; */
}
.verticalLine {
  border-left: 2px solid;
  flex: 0 1;
  /* height: 100%; */
  /* border-radius: 100px; */
  /* flex-grow: 1; */
}
.cardRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1 0 50%;
  padding-left: 25px;
  padding-right: 25px;
  /* background: rebeccapurple; */
}

.item-label {
  vertical-align: middle;
  border-style: solid;
}

.last-card {
  margin-bottom: 0;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.languages div {
  color: #72baee;
  margin: 3px;
  padding: 0px 4px 0px 4px;
  border-style: solid;
  border-width: 1.5px;
  border-radius: 5px;
}

.horizontalLine {
  margin-left: 30px;
  margin-right: 30px;
  border-top: 1px solid #3aafb9;
  width: 100%;
  height: 2px;
  border-radius: 100px;
}

.skillPage {
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  padding-bottom: 100px;
  /* border-style: solid; */
}

.skillColumn {
  /* width: 300px; */
  padding-left: 25px;
  padding-right: 25px;
  /* text-align: center; */
}

.leftAlign {
  display: inline-block;
  text-align: left;
}

.skillColumn p {
  margin-bottom: 5px;
}

.date {
  color: #037971;
}

.projectName {
  display: flex;
  flex-wrap: wrap;
}
.projectType {
  display: inline;
  font-style: italic;
  color: #6f42c1;
}
