@media only screen and (max-width: 1500px) {
  .itemCard {
    margin: 0;
    padding: 0;
  }
  .section {
    margin: 0;
    padding: 0;
  }
  .center {
    width: 100%;
  }
  .gnome {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .typing {
    font-size: 100px;
  }
  .itemCard {
    margin-left: 200px;
    margin-right: 200px;
    flex-direction: column;
  }
  .section {
    flex-direction: column;
  }
  .cardLeft {
    /* display: none; */
    margin-bottom: 50px;
  }
  .cardRight {
    margin-bottom: 0;
  }
  .verticalLine {
    display: none;
  }
  .horizontalLine {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 850px) {
  .itemCard {
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: column;
  }
  .cardLeft {
    margin-bottom: 50px;
  }
  .cardRight {
    margin-bottom: 0;
  }
  .verticalLine {
    display: none;
  }
  .skillPage {
    flex-direction: column;
    align-items: center;
  }
  .skillColumn {
    margin-bottom: 40px;
  }
  .leftAlign {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  /* TITLE PAGE ------------------------------------------------------------------- */
  .typing {
    font-size: 50px;
  }
  .navbar {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: space-around;
    background: transparent;
  }
  .center {
    width: 100%;
  }
  .contact {
    display: none;
  }

  .divider {
    margin-bottom: 0;
  }
  .up {
    display: none;
  }
  /* ITEM CARD -------------------------------------------------------------------- */
  .date {
    margin-bottom: 50px;
  }
  .introduction {
    margin-bottom: 50px;
  }
  .skillPage {
    border-top: solid;
    border-color: #c0c0c0;
    /* border-style: solid; */
    /* border-radius: 10px; */
    border-width: 1px;
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
  }
  .skillColumn {
    text-align: center;
  }
  .item {
    border-top: solid;
    border-color: #c0c0c0;
    /* border-style: solid; */
    /* border-radius: 10px; */
    border-width: 1px;
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
  }
  .horizontalLine {
    display: none;
  }
  .cardLeft {
    display: none;
  }
  .section {
    /* margin: 10px 10px 10px 10px; */
    /* padding: 10px 10px 10px 10px; */
    margin: 0;
    padding: 0;
  }

  .cardRight {
    padding: 0;
  }

  .title {
    display: none;
  }
  /* PROJECT CARD ----------------------------------------------------------------- */
  .projectName {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .githubButton {
    margin-left: 0;
  }
}
