.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #6cc644;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top:hover,
.hvr-sweep-to-top:focus,
.hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before,
.hvr-sweep-to-top:focus:before,
.hvr-sweep-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.githubButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  text-decoration: none;
  color: initial;
  background-color: white;
  margin-left: 20px;
  border-style: solid;
  border-color: #6cc644;
  border-radius: 5px;
  border-width: 2px;
  padding: 0px 5px 0px 5px;
  font-size: 20px;
}
.githubButton:hover {
  text-decoration: none;
  cursor: pointer;
}

.tiny {
  margin-left: 5px;
  display: inline;
  vertical-align: bottom;
  font-size: 13px;
}
