@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");

body {
  font-family: "Titillium Web", sans-serif;
}

h1 {
  text-align: center;
}

.typing {
  font-size: 150px;
  color: #001011;
}

.experienceLogo {
  /* height: 30px; */
  height: 35px;
  /* float: left; */
  /* border-style: solid; */
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  /* margin-bottom: 10px; */
}

.contact {
  display: flex;
  justify-content: space-between;
}

.contactImage {
  margin-top: 100px;
  height: 100px;
  transition: transform 0.2s;
}

.imageContainer {
  flex: 1 0 33%;
  align-items: center;
  text-align: center;
}

.contactImage:hover {
  cursor: pointer;
  transform: scale(1.1);
}

h1 {
  font-size: 120px;
  color: #093a3e;
}

h2 {
  font-size: 40px;
  color: #3aafb9;
}

p {
  font-size: 18px;
}

.center {
  width: 50%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.titlePage {
  height: 100vh;
}

.navbar {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  justify-content: space-around;
  background: transparent;
}

.navLink:hover {
  cursor: pointer;
}

.up {
  position: -webkit-sticky;
  position: fixed;
  bottom: 10px;
  right: 10px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.resume {
  border-style: solid;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  text-align: center;
  margin-bottom: 100px;
}

.resumeImage {
  height: 100%;
}

.resume:hover {
  cursor: pointer;
  animation-name: bounce;
  animation-timing-function: ease;
}

.up:hover {
  cursor: pointer;
  animation-name: bounce;
  animation-timing-function: ease;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-style: solid; */
  margin-bottom: 100px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

highlight {
  color: #7494ea;
  font-weight: bold;
}

.gnome {
  /* display: none; */
  /* position: fixed; */
  /* top: 0px; */
  /* right: 0px; */

  height: 0.5%;
  width: 0.5%;
}

.element {
  /* border-style: solid; */
  /* min-height: 100vh; */
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  padding-top: 50px;
}

h3 {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  /* border-style: solid; */
}
